import React from "react";
import "./MainNavigation.css";
import MainHeader from "./MainHeader";
//import Avatar from "../../UIelements/Avatar";
function MainNavigation() {
  return (
    <>
      <MainHeader className="container">
        <h3>MyCandidet</h3>
      </MainHeader>
    </>
  );
}

export default MainNavigation;
