import React from "react";
import "./Books.css";

function Books() {
  return (
    <div className="main-books_div">
      {/* <div className="banner_img">
        <img src="../../images/banner.jpg" alt="banner" />
      </div> */}
      <div className="small_img">
        <img src="../../images/img4.jpg" alt="img1" />

        <img src="../../images/img3second.jpg" alt="img3second" />
      </div>
    </div>
  );
}

export default Books;
